import React from 'react'
import { AppType } from 'types/App'

interface Props {
  logoImageUrl?: AppType['logo_image']
  appName?: AppType['app_name']
}

export const AppInfo: React.FC<Props> = ({ logoImageUrl, appName }) => {
  return (
    <div className="flex flex-col items-center pt-8">
      <div style={{ color: '#333333', fontSize: '23px', lineHeight: '117%' }}>
        アカウントの選択
      </div>
      <div className="mt-6">
        <img
          src={logoImageUrl}
          width={52}
          height={52}
          style={{
            border: '1px solid #BDBDBD',
            boxSizing: 'border-box',
            borderRadius: '6px',
          }}
        ></img>
      </div>
      <div
        className="mt-2"
        style={{
          color: '#1133A2',
          fontWeight: 'bold',
          fontSize: '15px',
          lineHeight: '117%',
        }}
      >
        {appName}
      </div>
      <div
        className="mt-1"
        style={{ color: '#070205', fontSize: '14px', lineHeight: '117%' }}
      >
        に移動します
      </div>
    </div>
  )
}
