import React, { useState, useEffect } from 'react'
import { navigate, PageProps } from 'gatsby'
import { cookies } from 'scripts/cookies'
import { useQueryParam, NumberParam } from 'use-query-params'
import { getApp } from 'api/getApp'
import { useIdentities } from 'api/useIdentities'
import { ToTransferState, ToAccountChooserState } from 'types/NavigateState'
import LockedLogo from 'images/lockedlogo.png'
import { AppType } from 'types/App'
import { AppInfo } from './AppInfo'
import { Loading } from 'presentation/components/Loading'

export const AccountChooser = (props: PageProps) => {
  const navigateState: ToAccountChooserState = props.location
    .state as ToAccountChooserState
  const sessionToken = cookies.get('session_token')
  const [appId] = useQueryParam('app_id', NumberParam)
  const [{ identities, loading }] = useIdentities({
    session_token: sessionToken,
    app_id: appId ?? undefined,
  })

  const [app, setApp] = useState<Partial<AppType>>()
  const [appLoading, setAppLoading] = useState(true)

  const navigateToAppsByFail = () => {
    navigate('/apps', {
      state: {
        snackbarText: 'ログインに失敗しました',
      },
      replace: true,
    })
    return
  }

  useEffect(() => {
    getApp({
      app_id: appId ?? undefined,
      session_token: sessionToken,
    })
      .then((response) => {
        setApp(response.data.app)
      })
      .catch((e) => {
        console.error(e)
        navigateToAppsByFail()
        return
      })
      .finally(() => {
        setAppLoading(false)
      })
  }, [])

  const pageLoading = () => {
    return loading || appLoading
  }

  useEffect(() => {
    if (!pageLoading() && typeof identities === 'undefined') {
      navigate('/apps')
      navigateToAppsByFail()
      return
    }
  }, [pageLoading, identities])

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: { appId: number; acId: string },
  ) => {
    e.preventDefault()
    let payload: ToTransferState = {}
    if (
      navigateState &&
      navigateState.samlRequest &&
      navigateState.relayState
    ) {
      payload = {
        samlRequest: navigateState.samlRequest,
        relayState: navigateState.relayState,
      }
    }
    navigate(`/transfer?app_id=${data.appId}&ac_id=${data.acId}`, {
      state: payload,
    })
    return
  }

  const renderIdentitiesRow = () => {
    if (loading || !identities) {
      return <></>
    }
    const listItems = identities!.map((identity) => {
      if (
        typeof identity.app_id === 'undefined' ||
        typeof identity.id === 'undefined'
      )
        return <></>
      return (
        <div
          data-test={app?.app_type}
          className="py-3 px-4 flex"
          style={{
            minHeight: 64,
            width: '100%',
            borderTop: '1px solid #E0E0E0',
            cursor: 'pointer',
          }}
          key={identity.id}
          onClick={(e) => handleClick(e, { appId: appId!, acId: identity.id })}
        >
          <div
            className="flex flex-col justify-center"
            style={{
              width: 266,
            }}
          >
            <div
              style={{
                color: '#333333',
                letterSpacing: '0.03em',
                fontWeight: 'bold',
                fontSize: '15px',
                lineHeight: '125%',
                wordBreak: 'break-word',
              }}
            >
              {identity.display_label ? identity.display_label : '---'}
            </div>
            <div
              style={{
                color: '#626262',
                fontSize: '13px',
                lineHeight: '125%',
                letterSpacing: '0.03em',
                wordBreak: 'break-word',
              }}
            >
              {identity.user_id}
            </div>
          </div>
          <div style={{ width: 30 }} className="flex justify-end items-center">
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.5 10L5.5 5L0.5 0L0.5 10Z" fill="#BDBDBD" />
            </svg>
          </div>
        </div>
      )
    })
    return listItems
  }

  const renderContents = () => {
    if (pageLoading()) {
      return (
        <div
          className="h-full w-full flex justify-center items-center"
          style={{ marginTop: '150px' }}
        >
          <Loading width={32}></Loading>
        </div>
      )
    }

    return (
      <>
        <AppInfo
          logoImageUrl={app?.logo_image}
          appName={app?.app_name}
        ></AppInfo>
        <div className="flex justify-center py-10" style={{ width: '100%' }}>
          <div
            style={{
              width: 320,
              boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            {renderIdentitiesRow()}
          </div>
        </div>
      </>
    )
  }

  return (
    <div
      data-test="accountChooserPage"
      className="pt-12 mx-auto pb-8"
      style={{ background: '#E5E5E5', minHeight: '100%' }}
    >
      <div
        className="mx-auto"
        style={{
          width: '100%',
          maxWidth: 476,
          background: '#FFF',
          border: '2px solid #E0E0E0',
          boxSizing: 'border-box',
          borderRadius: '6px',
          minHeight: 428,
        }}
      >
        <div
          className="flex justify-center items-center"
          style={{ height: 41, borderBottom: '1px solid #E0E0E0' }}
        >
          <img
            src={LockedLogo}
            style={{
              width: 87,
              display: 'block',
            }}
          />
        </div>
        {renderContents()}
      </div>
    </div>
  )
}
