import { GetIdentitiesResponse } from 'types/ApiResponse'
import { useAxios } from 'scripts/api'
import { GetIdentitiesRequest } from 'types/ApiRequest'
import { fullUrl } from 'scripts/fullUrl'

export const useIdentities = (params: GetIdentitiesRequest) => {
  const [{ data, error, loading }] = useAxios<GetIdentitiesResponse>({
    url: `${fullUrl()}/apps/${params.app_id}/identities`,
    params: params,
  })
  return [{ ...data, error, loading }]
}
