import { GetAppResponse } from 'types/ApiResponse'
import { GetAppRequest } from 'types/ApiRequest'
import { fullUrl } from 'scripts/fullUrl'
import { axiosClient } from 'scripts/api'

export const getApp = async (params: GetAppRequest) => {
  const res = await axiosClient.get<GetAppResponse>(
    `${fullUrl()}/apps/${params.app_id}`,
    { params },
  )
  return res
}
